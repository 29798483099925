export default {
  // 自动读取文件夹,并进行组件注册
  install(Vue) {
    // require.context()  自动读取文件夹
    // 可以传入三个参数  第一个为 路径  第二个 为是否读取文件  , 第三个为  正则匹配  读取通过正则匹配的文件
    // 该方法返回一个函数
    // 函数中 有一个 keys() 方法  方法返回一个数组  每一个数组元素都是被读取的文件的路径
    const req = require.context('./', true, /\.vue$/)
    req.keys().forEach((path) => {
      const temp = req(path).default
      Vue.component(temp.name, temp)
    })
  }
}
