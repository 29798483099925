import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    redirect: "/",
    component: () => import("@/layout/layout.vue"),
    children: [
      {
        path: "/",
        name: "index",
        component: () => import("@/views/home/Home.vue"),
      },
      {
        path: "product",
        name: "product",
        component: () => import("@/views/product/product.vue"),
      },
      {
        path: "news-center",
        name: "news-center",
        component: () => import("@/views/news/News.vue"),
      },
      {
        path: "newsDetail",
        name: "newsDetail",
        component: () => import("@/views/news/components/NewsDetails.vue"),
      },
      {
        path: "PaperCatalog",
        name: "PaperCatalog",
        component: () => import("@/views/news/components/PaperCatalog.vue"),
      },
      {
        path: "ThesisDetail",
        name: "ThesisDetail",
        component: () => import("@/views/news/components/ThesisDetail.vue"),
      },
      {
        path: "company-info",
        name: "company-info",
        component: () => import("@/views/AboutUs/AboutUs.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
});

export default router;
