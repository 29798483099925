

// 定义一个对象 对象的key为自定义指令的名字  key值为自定义指令的生命周期
const directivesObj = {
  // 指令名
  errorImg: {
    // 针对img图片的指令
    inserted(dom, obj) {
      // 当img没有src属性时 使用默认传入的图片   默认图片可以通过传入变量进行更改
      dom.src = dom.src || obj.value || require('@/assets/image/财税政策-卡片bg.png')

      // 当src绑定的图片渲染失败时,显示默认的图片
      dom.onerror = () => {
        dom.src = obj.value || require('@/assets/image/财税政策-卡片bg.png')
      }
    },
    // 更新后图片的处理
    componentUpdated(dom, obj) {
      dom.src = dom.src || obj.value || require('@/assets/image/财税政策-卡片bg.png')
    }
  },
}

// 全局自定义指令注册
export default function (Vue) {

  Object.keys(directivesObj).forEach((key) =>
    Vue.directive(key, directivesObj[key])
  )
}
