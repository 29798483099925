import router from "./router";
import store from "./store";
const navList = ["index", "product", "news-center", "my-ruoyu", "company-info"];
router.beforeEach(async (to, from, next) => {
  const index = navList.findIndex((item) => {
    return item === to.name;
  });
  store.commit("navBar/setNavId", `${index}`);

  next();
});
