import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import lib from '@/components/index'
// 导入路由守卫
import '@/permission'
import "@/assets/styles/index.scss"

import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

// highlightjs
import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});





// 导入全局自定义指令
import directives from '@/directives/index'
// 导入适配插件
// import 'amfe-flexible'
import BaiduMap from 'vue-baidu-map'
// 导入iconfont
import './assets/icons/iconfont.css'


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 挂载事件总线
Vue.prototype.$bus = new Vue()

Vue.config.productionTip = false;
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'Y3lsKtREd1POPNgqpSwtz4YbMqfWtWKN'
})
Vue.use(ElementUI)
Vue.use(directives)
Vue.use(VMdPreview);
Vue.use(lib);


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
