export default {
  namespaced: true,
  state: {
    navId: "0",
    navColor: false,
    navLeft:0,
  },
  mutations: {
    setNavId: (state, value) => {
      state.navId = value;
    },
    setNavColor: (state, value) => {
      state.navColor = value;
    },
    setNavLeft: (state, value) => {
      state.navLeft = value;
    }
  },
  actions: {},
  getters: {},
};
